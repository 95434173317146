import { ApiClient } from '@gls_spain/frontend-api-client';
import { min } from 'lodash';

export default class GPSApiClient extends ApiClient {
  async login({ username, password }) {
    const response = await this.axiosInstance.get('/login', {
      auth: { username, password },
      headers: {
        'authorization-origin': 'admin',
      },
    });
    if (response.data.network) {
      response.data.user.network = response.data.network;
    }
    return response.data.user;
  }

  async self() {
    const response = await this.axiosInstance.get('/self');
    if (response.data.network) {
      response.data.user.network = response.data.network;
    }
    return response.data.user;
  }

  async become({ _id }) {
    const response = await this.axiosInstance.post('/become', { _id });
    if (response.data.network) {
      response.data.user.network = response.data.network;
    }
    return response.data.user;
  }

  async superAdminGetSelf() {
    return this.get('/self');
  }

  async findSuperAdmins(body) {
    // throw new Error('TEST');
    return this.post('/superAdmins/find', body);
  }

  async deleteSuperAdmins(body) {
    return this.post('/superAdmins/delete', body);
  }

  async createSuperAdmins(body) {
    return this.post('/superAdmins/create', body);
  }

  async updateSuperAdmins(body) {
    return this.post('/superAdmins/update', body);
  }

  async findNetworks(body) {
    return this.post('/networks/find', body);
  }

  async deleteNetworks(body) {
    return this.post('/networks/delete', body);
  }

  async updateNetwork(body) {
    return this.post('/networks/update', body);
  }

  async createNetworks(body) {
    return this.post('/networks/create', body);
  }

  async findNetworkAdmins(body) {
    return this.post('/networkAdmins/find', body);
  }

  async deleteNetworkAdmins(body) {
    return this.post('/networkAdmins/delete', body);
  }

  async updateNetworkAdmins(body) {
    return this.post('/networkAdmins/update', body);
  }

  async createNetworkAdmins(body) {
    return this.post('/networkAdmins/create', body);
  }

  async findShops(body) {
    return this.post('/shops/find', body);
  }

  async findContactForm(id) {
    return this.get(`/contactForm/find/${id}`, {
      timeout: 45000,
    });
  }

  async deleteShop(body) {
    return this.post('/shops/delete', body);
  }

  async updateShop(body) {
    return this.post('/shops/update', body);
  }

  async createShop(body) {
    return this.post('/shops/create', body);
  }

  async listNetworksNames() {
    return this.get('/networks/listNames');
  }

  async listLockersNetworksNames() {
    return this.get('/networks/listLockersNames');
  }

  async listInternationalPartnerKey() {
    return this.get('/international/partnerKey');
  }

  async findShopUsers(body) {
    return this.post('/shopUsers/find', body);
  }

  async deleteShopUser(body) {
    return this.post('/shopUsers/delete', body);
  }

  async updateShopUser(body) {
    return this.post('/shopUsers/update', body);
  }

  async createShopUser(body) {
    return this.post('/shopUsers/create', body);
  }

  async findSalesRepresentative(body) {
    return this.post('/representatives/find', body);
  }

  async deleteSalesRepresentative(body) {
    return this.post('/representatives/delete', body);
  }

  async createSalesRepresentative(body) {
    return this.post('/representatives/create', body);
  }

  async updateSalesRepresentative(body) {
    return this.post('/representatives/update', body);
  }

  async getListSalesRepresentativeName() {
    return this.get('/representatives/listNames');
  }

  async getListNetworkName() {
    return this.get('/operations/listNamesNetworks');
  }

  async getListZonesName() {
    return this.get('/zones/listNames');
  }

  async findZones(body) {
    return this.post('/zones/find', body);
  }

  async deleteZones(body) {
    return this.post('/zones/delete', body);
  }

  async createZones(body) {
    return this.post('/zones/create', body);
  }

  async updateZone(body) {
    return this.post('/zones/update', body);
  }

  async findExpeditions(body) {
    return this.post('/expeditions/find', body);
  }

  async excelCommertial(arrayData) {
    // Comercial y desde/hasta
    if (arrayData[3].length === 0 && arrayData[0] !== null && arrayData[1] !== null && arrayData[4] === '') {
      return this.get(
        `/representatives/downloadExcelRepresentatives/${arrayData[0]}/${arrayData[1]}/${arrayData[2]}/none/none`,
        {
          timeout: 45000,
        },
      );
    }

    // Comercial, desde/hasta  y capacidades
    if (arrayData[3].length !== 0 && arrayData[0] !== null && arrayData[1] !== null && arrayData[4] === '') {
      return this.get(
        `/representatives/downloadExcelRepresentatives/${arrayData[0]}/${arrayData[1]}/${arrayData[2]}/${arrayData[3]}/none`,
        {
          timeout: 45000,
        },
      );
    }

    // Comercial y capacidades
    if (arrayData[3].length !== 0 && arrayData[0] === null && arrayData[1] === null && arrayData[4] === '') {
      return this.get(`/representatives/downloadExcelRepresentatives/none/none/${arrayData[2]}/${arrayData[3]}/none`, {
        timeout: 45000,
      });
    }

    // Comercial, desde/hasta y pais
    if (arrayData[3].length === 0 && arrayData[0] !== null && arrayData[1] !== null && arrayData[4] !== '') {
      return this.get(
        `/representatives/downloadExcelRepresentatives/${arrayData[0]}/${arrayData[1]}/${arrayData[2]}/none/${arrayData[4]}`,
        {
          timeout: 45000,
        },
      );
    }

    // Comercial, capacidades y pais
    if (arrayData[3].length !== 0 && arrayData[0] === null && arrayData[1] === null && arrayData[4] !== '') {
      return this.get(
        `/representatives/downloadExcelRepresentatives/none/none/${arrayData[2]}/${arrayData[3]}/${arrayData[4]}`,
        {
          timeout: 45000,
        },
      );
    }

    // Comercial
    if (arrayData[3].length === 0 && arrayData[0] === null && arrayData[1] === null && arrayData[4] === '') {
      return this.get(`/representatives/downloadExcelRepresentatives/none/none/${arrayData[2]}/none/none`, {
        timeout: 45000,
      });
    }

    // Comercial y pais
    if (arrayData[3].length === 0 && arrayData[0] === null && arrayData[1] === null && arrayData[4] !== '') {
      return this.get(`/representatives/downloadExcelRepresentatives/none/none/${arrayData[2]}/none/${arrayData[4]}`, {
        timeout: 45000,
      });
    }

    // Comercial, desde/hasta, capacidades y pais
    return this.get(
      `/representatives/downloadExcelRepresentatives/${arrayData[0]}/${arrayData[1]}/${arrayData[2]}/${arrayData[3]}/${arrayData[4]}`,
      {
        timeout: 45000,
      },
    );
  }

  async excelShop(arrayData) {
    if (arrayData[3].length === 0 && arrayData[0] !== null && arrayData[1] !== null) {
      return this.get(`/representatives/downloadExcelShops/${arrayData[0]}/${arrayData[1]}/${arrayData[2]}/none`, {
        timeout: 45000,
      });
    }
    if (arrayData[3].length !== 0 && arrayData[0] === null && arrayData[1] === null) {
      return this.get(`/representatives/downloadExcelShops/none/none/${arrayData[2]}/${arrayData[3]}`, {
        timeout: 45000,
      });
    }
    if (arrayData[3].length === 0 && arrayData[0] === null && arrayData[1] === null) {
      return this.get(`/representatives/downloadExcelShops/none/none/${arrayData[2]}/none`, {
        timeout: 45000,
      });
    }
    return this.get(
      `/representatives/downloadExcelShops/${arrayData[0]}/${arrayData[1]}/${arrayData[2]}/${arrayData[3]}`,
      {
        timeout: 45000,
      },
    );
  }

  async excelSales(arrayData) {
    return this.get(`/sales/report/${arrayData[0]}/${arrayData[1]}`, {
      timeout: 75000,
    });
  }

  async excelQualities(arrayData) {
    return this.get(`/qualities/report/${arrayData[0]}/${arrayData[1]}/${arrayData[2]}`, { timeout: 120000 });
  }

  async excelOperations(arrayData) {
    return this.get(`/operations/report/${arrayData[0]}/${arrayData[1]}/${arrayData[2]}}/${arrayData[3]}`, {
      timeout: 120000,
    });
  }

  async deleteExpeditions(body) {
    return this.post('/expeditions/delete', body);
  }

  async getGlobalDefaults() {
    return this.get('/config/defaults/get');
  }

  async setGlobalDefaults(body) {
    return this.post('/config/defaults/update', body);
  }

  async findSales(body) {
    return this.post('/sales/find', body);
  }

  async expireExpedition(body) {
    return this.post('/expeditions/expire', body);
  }

  async unexpireExpedition(body) {
    return this.post('/expeditions/unexpire', body);
  }

  async updateDerivation(body) {
    return this.post('/expeditions/updateDerivation', body);
  }

  // Communications

  async findCommunications(body) {
    return this.post('/communications/find', body);
  }

  async deleteCommunication(body) {
    return this.post('/communications/delete', body);
  }

  async updateCommunication(body) {
    return this.post('/communications/update', body);
  }

  async createCommunication(body) {
    return this.post('/communications/create', body);
  }
}
