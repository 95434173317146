export default {
  superAdmins: 'Super administrador',
  networks: 'Redes',
  parcelShops: 'Shops',
  customerSupport: 'Soporte',
  salesRepresentative: 'Comerciales',
  communications: 'Comunicados',
  zones: 'Zonas',
  expeditions: 'Expediciones',
  settings: 'Configuraciones',
  reports: 'Informes',
};
