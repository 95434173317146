export default {
  listSuperAdmins: 'Super admins',
  createSuperAdmin: 'Add super admin',
  representatives: 'Sales representatives',
  createRepresentative: 'Add representative',
  listNetworks: 'Networks',
  createNetwork: 'Add network',
  createNetworkAdmin: 'Add network admin',
  listParcelShops: 'Parcel shops',
  createCommunication: 'Add Communication',
  listCommunications: 'Communications',
  listLockers: 'Lockers',
  createShop: 'Add parcel shop',
  createShopUsers: 'Add shop user',
  zones: 'Zones',
  zonesCreate: 'Add zone',
  expeditions: 'Expeditions',
  settings: 'Settings',
  reports: 'Reports',
  reportCommercial: 'Commercial report',
  reportShops: 'General report',
  reportSales: 'Sales report',
  reportQuality: 'Quality report',
  reportOperations: 'Operations report',
};
